import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import useIsLargeScreen from '../../hooks/useIsLargeScreen';
import Avatar from './../Avatar';
import { useModal } from './../Modal';
import UserMenu from '../MenuUser';
import useAuth from './useAuth';

// eslint-disable-next-line react/prop-types
const AuthAvatar = ({ userName = [] }) => {
  // eslint-disable-next-line react/prop-types
  const initialLetter = userName?.[0];
  return (
    <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-indigo-700">
      <span className="font-medium text-xl leading-none text-white">
        {initialLetter}
      </span>
    </span>
  );
};

const AuthButton = () => {
  let isLargeScreen = useIsLargeScreen();
  const { user, openAuthModal, signOut } = useAuth();
  const { closeModal } = useModal();
  const router = useRouter();
  const { t } = useTranslation();

  const goToProfile = () => {
    closeModal();
    sessionStorage.setItem('previousPath', location.pathname);
    router.push('/user/profile');
  };
  const goToOrders = () => {
    closeModal();
    sessionStorage.setItem('previousPath', location.pathname);
    router.push('/user/orders');
  };
  const userNavigation = [
    { name: t('userNavigation.profile'), onClick: goToProfile },
    { name: t('userNavigation.orders'), onClick: goToOrders },
    { name: t('signOut'), onClick: signOut },
  ];

  if (user) {
    return (
      <UserMenu
        userEmail={user.email}
        options={userNavigation}
        isBottom={!isLargeScreen}
      >
        <span className="sr-only">Authenticate</span>
        {user ? <AuthAvatar userName={user.name} /> : <Avatar />}
      </UserMenu>
    );
  } else {
    return (
      <button onClick={openAuthModal}>
        <span className="sr-only">Authenticate</span>
        {user ? <AuthAvatar userName={user.name} /> : <Avatar />}
      </button>
    );
  }
};

export default AuthButton;
